import React, { useState, useEffect } from "react";
import classNames from "classnames";
import useCategoriesData from "@staticQueries/CategoriesQuery";
import { CaseStudyCard } from "@molecules";
import { Dropdown, Text, Container } from "@atoms";
import { AnimatePresence, m } from "framer-motion";

const getUids = options => options.map(option => option.uid);
const CaseStudyList = ({ items, className: _className }) => {
  // list only modules included in case studies
  const categories = items
    ?.flatMap(item => [{ uid: 0, title: "All" }, ...item.modules])
    // filter duplicate modules
    ?.filter(
      (value, index, self) =>
        index === self.findIndex(option => option.uid === value.uid)
    );

  const audiences = [
    { uid: "all", title: "All" },
    ...useCategoriesData().audiences,
  ];

  const [category, setCategory] = useState(0);
  const [audience, setAudience] = useState(0);
  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    setFilteredItems(
      items?.filter(
        item =>
          (category === 0 ||
            getUids(item.modules)?.includes(categories[category]?.uid)) &&
          (audience === 0 ||
            getUids(item.audiences)?.includes(audiences[audience].uid))
      )
    );
  }, [category, audience]);

  // set category based on the url parameter
  // const urlFilter =
  // typeof window !== "undefined" ? window.location?.hash?.split("#")[1] : null;

  // useEffect(() => {
  //   if (urlFilter) {
  //     setCategory(categories.findIndex(t => t.uid === urlFilter));
  //   }
  // }, [urlFilter]);

  const defaults = {
    type: "tween",
    duration: 0.5,
  };

  const variants = {
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        ...defaults,
      },
    },
    exit: {
      y: 20,
      opacity: 0,
      transition: {
        ...defaults,
      },
    },
  };

  return (
    <section
      className={classNames("relative z-20 pt-10 pb-5 sm:py-10", _className)}
    >
      <Container>
        {/* filters */}
        <div className="flex flex-wrap gap-5">
          <div className="flex flex-[1_1_100%] items-center space-x-2 sm:flex-initial">
            <Text variant="label">Category:</Text>
            <Dropdown
              model={[category, setCategory]}
              options={categories}
              size="sm"
            />
          </div>
          <div className="flex flex-[1_1_100%] items-center space-x-2 sm:flex-initial">
            <Text variant="label">Audience:</Text>
            <Dropdown
              model={[audience, setAudience]}
              options={audiences}
              size="sm"
            />
          </div>
        </div>
        {/* filtered items */}
        <div className="mt-10 flex flex-col items-center space-y-10">
          <AnimatePresence mode="wait">
            <div className="grid w-full grid-cols-2 gap-6">
              {!!filteredItems?.length &&
                filteredItems.map(item => {
                  return (
                    <m.div
                      key={item.uid}
                      initial="exit"
                      animate="enter"
                      exit="exit"
                      variants={variants}
                      className="col-span-2 md:col-span-1"
                    >
                      <CaseStudyCard {...item} />
                    </m.div>
                  );
                })}
              {!filteredItems?.length && (
                <m.div
                  initial="exit"
                  animate="enter"
                  exit="exit"
                  variants={variants}
                  className="col-span-2 flex flex-col items-center justify-center"
                >
                  <Text variant="xl">
                    There are no items that match your selected filters.
                  </Text>
                </m.div>
              )}
            </div>
          </AnimatePresence>
        </div>
      </Container>
    </section>
  );
};

CaseStudyList.defaultProps = {};

export default CaseStudyList;
